import { Flex, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import React from 'react'

interface FeatureItemProps {
  icon: React.ReactNode
  title: string
  description: string
}

const FeatureItem = ({ icon, title, description }: FeatureItemProps) => {
  const { theme } = useTheme()
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      // width={['100%', '100%', '45%', '30%']}
      mb={[4, 4, 0]}
    >
      <Flex
        mb={2}
        p={3}
        borderRadius="100%"
        color={theme.isDark ? '#D8B4FE' : '#9333ea'}
        bg={theme.isDark ? '#4C1D95' : '#f3e8ff'}
      >
        {icon}
      </Flex>
      <Text
        as="h3"
        fontSize={['20px', '22px', '24px']}
        fontWeight="bold"
        color={theme.isDark ? '#D8B4FE' : '#7e22ce'}
        mb={1}
        textAlign="center"
      >
        {title}
      </Text>
      <Text color={theme.isDark ? '#E9D5FF' : '#9333ea'} textAlign="center" fontSize={['14px', '16px']}>
        {description}
      </Text>
    </Flex>
  )
}

interface FeatureSectionProps {
  title: string
  features: FeatureItemProps[]
}

const FeatureSection: React.FC<FeatureSectionProps> = ({ title, features }) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const { theme } = useTheme()
  return (
    <Flex
      // as="section"
      width="100%"
      // py={[8, 12, 16]}
      bg="purple.50"
    >
      <Flex flexDirection="column" width="100%" mx="auto" px={[4, 6]}>
        <Text
          as="h2"
          fontSize={['24px', '32px', '40px']}
          fontWeight="bold"
          textAlign="center"
          color={theme.isDark ? '#D8B4FE' : '#7e22ce'}
          mb={6}
        >
          {title}
        </Text>
        <Flex
          flexDirection={['column', 'column', 'row']}
          flexWrap="wrap"
          justifyContent="center"
          alignItems={['center', 'center', 'flex-start']}
          mx={-2}
        >
          {/* {features.map((feature, index) => (
                //   <FeatureItem key={index} {...feature} />
                isMobile ? (
                    <Flex key={index} width="100%" justifyContent="center">
                        <FeatureItem {...feature} />
                    </Flex>
                ) : isTablet ? (
                    <Flex key={index} width="calc(50% - 8px)" justifyContent="center">
                        <FeatureItem {...feature} />
                    </Flex>
                    {features.length === 3 && <Flex width="calc(50% - 8px)" />}
                ) : (
                    <Flex key={index} width="calc(25% - 12px)" justifyContent="center">
                        <FeatureItem {...feature} />
                    </Flex>
                )
                ))} */}
          {features.map((feature, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {isMobile ? (
                <Flex width="100%" justifyContent="center">
                  <FeatureItem {...feature} />
                </Flex>
              ) : isTablet ? (
                <Flex width="calc(50% - 8px)" justifyContent="center">
                  <FeatureItem {...feature} />
                  {features.length === 3 && index === 1 && <Flex width="calc(50% - 8px)" />}
                </Flex>
              ) : (
                <Flex width="calc(33.33% - 16px)" justifyContent="center">
                  <FeatureItem {...feature} />
                </Flex>
              )}
            </React.Fragment>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FeatureSection
