import React from 'react'
import { Flex, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'

interface StatItemProps {
  value: string
  label: string
}

const StatItem: React.FC<StatItemProps> = ({ value, label }) => {
  const { theme } = useTheme()
  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" p={4}>
      <Text fontSize={['24px', '28px', '32px']} fontWeight="bold" color={theme.isDark ? '#D8B4FE' : '#7e22ce'} mb={2}>
        {value}
      </Text>
      <Text fontSize={['16px', '18px', '20px']} color={theme.isDark ? '#E9D5FF' : '#9333ea'} textAlign="center">
        {label}
      </Text>
    </Flex>
  )
}

interface StatsSectionProps {
  title: string
  stats: StatItemProps[]
}

const StatsSection: React.FC<StatsSectionProps> = ({ title, stats }) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const { theme } = useTheme()

  return (
    <Flex
      width="100%"
      // py={}
    >
      <Flex flexDirection="column" maxWidth="1200px" mx="auto" px={[4, 6]}>
        <Text
          as="h2"
          fontSize={['24px', '32px', '40px']}
          fontWeight="bold"
          textAlign="center"
          color={theme.isDark ? '#D8B4FE' : '#6b21a8'}
          mb={4}
        >
          {title}
        </Text>
        <Flex flexWrap="wrap" justifyContent="center">
          {isMobile
            ? stats.map((stat, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Flex key={index} width="100%" justifyContent="center">
                  <StatItem {...stat} />
                </Flex>
              ))
            : isTablet
            ? stats.map((stat, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Flex key={index} width="calc(50% - 8px)" justifyContent="center">
                  <StatItem {...stat} />
                </Flex>
              ))
            : stats.map((stat, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Flex key={index} width="calc(25% - 12px)" justifyContent="center">
                  <StatItem {...stat} />
                </Flex>
              ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default StatsSection
