import { useTranslation } from '@pancakeswap/localization'
import { Box, PageSection, useMatchBreakpoints } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
// import MultipleBanner from './components/Banners/MultipleBanner'
// import CakeDataRow from './components/CakeDataRow'
// import CakeSection from './components/CakeSection'
import { styled } from 'styled-components'
// import EcoSystemSection from './components/EcoSystemSection'
import Footer from './components/Footer'
import Hero from './components/Hero'

import { InnerWedgeWrapper, OuterWedgeWrapper, WedgeTopLeft } from './components/WedgeSvgs'
import MultipleBanner from './components/Banners/MultipleBanner'
import MetricsSection from './components/MetricsSection'
import FeatureSection from './components/FeatureSection'
import StatsSection from './components/StatsSection'
// import MetricsSection from './components/MetricsSection'
// import { NewsSection } from './components/NewsSection'

const StyledHeroSection = styled(PageSection)`
  padding-top: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px', padding: '0px 16px' }
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()

  const features = [
    {
      icon: (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M25.333 6.667L6.667 25.333" />
          <circle cx="8.667" cy="8.667" r="3.333" />
          <circle cx="23.333" cy="23.333" r="3.333" />
        </svg>
      ),
      title: 'Low Fees',
      description: 'Trade with some of the lowest fees in the DeFi space.',
    },
    {
      icon: (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M16 29.333s10.667-5.333 10.667-13.333V6.667L16 2.667l-10.667 4v9.333c0 8 10.667 13.333 10.667 13.333" />
          <path d="m12 16 2.667 2.667L20 13.333" />
        </svg>
      ),
      title: 'Secure & Reliable',
      description: 'Your assets are protected by industry-leading security measures.',
    },
    {
      icon: (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="16" cy="6.667" r="2.667" />
          <circle cx="6.667" cy="25.333" r="2.667" />
          <circle cx="25.333" cy="25.333" r="2.667" />
          <path d="M16 9.333v5.334" />
          <path d="m9.333 22.667-2.666 2.666" />
          <path d="m22.667 22.667 2.666 2.666" />
          <path d="m16 14.667-6.667 8" />
          <path d="m16 14.667 6.667 8" />
        </svg>
      ),
      title: 'Diverse Opportunities',
      description: 'From trading to yield farming, find your perfect DeFi strategy.',
    },
  ]

  const stats = [
    { value: '$10B+', label: 'Total Value Locked' },
    { value: '2M+', label: 'Users' },
    { value: '100+', label: 'Supported Tokens' },
    { value: '$500M+', label: 'Total Rewards Distributed' },
  ]

  return (
    <Box style={{ width: isMobile ? '100vw' : 'calc(100vw - 8px)', overflow: 'hidden', boxSizing: 'border-box' }}>
      <style jsx global>
        {`
          #home-1 .page-bg {
            background: linear-gradient(139.73deg, #f3e8ff 0%, #e9d5ff 100%);
          }
          [data-theme='dark'] #home-1 .page-bg {
            background: radial-gradient(180deg, #111827 0%, #581C87 100%);
          }
          #home-2 .page-bg {
            background: #faf5ff;
          }
          [data-theme='dark'] #home-2 .page-bg {
            background: #111827;
          }
          #home-3 .page-bg {
            background: #faf5ff;
          }
          [data-theme='dark'] #home-3 .page-bg {
            background: #111827;
          }
          #home-4 .page-bg {
            background: #f3e8ff;
          }
          [data-theme='dark'] #home-4 .page-bg {
            background: rgb(88 28 135);
          }
            #home-5 .page-bg {
            background: linear-gradient(180deg, #a855f7 0%, #f3e8ff 100%);
          }
          [data-theme='dark'] #home-5 .page-bg {
            background: linear-gradient(180deg, #6b21a8 0%, #3b0764 100%);
          }
          }
        `}
      </style>
      <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%', overflow: 'visible', padding: '16px' } }}
        containerProps={{
          id: 'home-1',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        {/* <MultipleBanner /> */}
        <Hero />
      </StyledHeroSection>

      <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        containerProps={{
          id: 'home-2',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <FeatureSection title="Why Choose GungnirDEX?" features={features} />
      </PageSection>
      <PageSection
        innerProps={{ style: { ...HomeSectionContainerStyles, maxWidth: 'auto' } }}
        background={theme.colors.background}
        containerProps={{
          id: 'home-3',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <StatsSection title="GungnirDEX Stats" stats={stats} />
      </PageSection>

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        containerProps={{
          id: 'home-4',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <Footer />
      </PageSection>
    </Box>
  )
}

export default Home
